<template>
  <v-container fluid fill-height>
    <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title
          >#{{ selectedOrder.id }} - Order Number
          {{ selectedOrder.customerOrderNo }}</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row v-for="(item, i) in selectedOrder.lineItems" :key="i">
              <v-col>
                {{ item.name }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="orders-header">
        <span>Draft Orders</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="draftHeaders"
          :items="draftOrders"
          disable-pagination
          :hide-default-footer="true"
        >
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | currency }}
          </template>
          <template v-slot:[`item.createdDate`]="{ item }">
            {{ item.createdDate | prettyDate }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon color="orange" @click="loadDraftOrder(item.id)">
                <v-icon>mdi-cart-arrow-down</v-icon>
              </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="orders-header">
        <span>Completed Orders</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="orders"
          disable-pagination
          :hide-default-footer="true"
        >
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | currency }}
          </template>
          <template v-slot:[`item.createdDate`]="{ item }">
            {{ item.createdDate | prettyDate }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              :to="{
                name: 'View',
                params: { orderId: item.id },
              }"
            >
              <v-btn icon color="orange">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Order Number", value: "customerOrderNo" },
        { text: "Date", value: "createdDate" },
        { text: "Total", value: "total" },
        { text: "View", value: "actions" },
      ],
      draftHeaders: [
        { text: "Name", value: "name" },
        { text: "Order Number", value: "customerOrderNo" },
        { text: "Date", value: "createdDate" },
        { text: "Total", value: "total" },
        { text: "Load", value: "actions" },
      ],
      showDialog: false,
      selectedOrder: {},
    };
  },
  computed: {
    orders() {
      return this.$store.state.account.orders;
    },
    draftOrders() {
      return this.$store.state.account.draftOrders;
    },
  },
  methods: {
    selectOrder(order) {
      this.selectedOrder = order;
      this.showDialog = true;
    },
    loadDraftOrder(orderId) {
      this.$store.dispatch('loadDraftOrder', orderId);
      this.$router.push('store');
    }
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.orders-header {
  font-size: 18px;
  padding-bottom: 0px;
}
</style>