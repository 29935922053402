var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-dialog',{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("#"+_vm._s(_vm.selectedOrder.id)+" - Order Number "+_vm._s(_vm.selectedOrder.customerOrderNo))]),_c('v-card-text',[_c('v-container',_vm._l((_vm.selectedOrder.lineItems),function(item,i){return _c('v-row',{key:i},[_c('v-col',[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"orders-header"},[_c('span',[_vm._v("Draft Orders")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.draftHeaders,"items":_vm.draftOrders,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyDate")(item.createdDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.loadDraftOrder(item.id)}}},[_c('v-icon',[_vm._v("mdi-cart-arrow-down")])],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"orders-header"},[_c('span',[_vm._v("Completed Orders")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyDate")(item.createdDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'View',
              params: { orderId: item.id },
            }}},[_c('v-btn',{attrs:{"icon":"","color":"orange"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }